import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils"
import { sitename } from "@constants";
import { useStaticQuery, graphql } from "gatsby";
import { isSafari, isIOS, isMacOs } from "react-device-detect";
import $ from "jquery";
import logo from "../../images/logo.svg"
import logoNew from "../../images/redbrik-new-logo.svg"
import logo02 from "../../images/tab-logo.png"
import logo03 from "../../images/desktop.png"
import { Container, Row, Col } from "react-bootstrap"
import Helmet from 'react-helmet'
import GenerateLink from "../common/site/generate-link"
import SearchForm from "./Search"
import "./Header.scss"
import parse from 'html-react-parser';
import { isTablet, isMobile, isMobileOnly } from 'react-device-detect';

const Header = (props) => {
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    //console.log('aaaaaaaaaaaaaa',isSafari,isIOS)
    setClient(true)
    if (isSafari === true || isIOS === true || isMacOs === true) {
      $('body').addClass('iosdevicesonly');
    }
    if (sessionStorage.getItem("StickyHeadet") != "1") {
      setSticky(false)
      //$('body').addClass('withStickyBand');

    }

  });


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusBurgers(sort: "Sort:asc") {
        Parent_Label
        Parent_Link {
          id
        }
        Sort
        Sub_Menus {
          Label
          Link {
            id
          }
        }
      }


      siteConfig {
        Site_Contact
        Burger_Menu_Quick_Links {
          Label
          Link {
            id
          }
        }
        Top_Navigation_Links {
          Label
          Link {
            id
          }
          Add_Sub_Menu {
            Submenu_Label
            Submenu_Link {
              id
            }
          }
        }
      }

    }
  }
`);



  const menus = data.glstrapi.menusBurgers;
  const quick_links = data.glstrapi.siteConfig.Burger_Menu_Quick_Links;
  const top_links = data.glstrapi.siteConfig.Top_Navigation_Links;
  const site_contact = data.glstrapi.siteConfig.Site_Contact;



  const [state, setState] = useState(menus);
  const [statetop, setStateTop] = useState(top_links);
  const [sticky, setSticky] = useState(false);

  const [issubmenutop, setIssubmenuTop] = useState([]);
  const [issubmenu, setIssubmenu] = useState([]);
  const [ham, setHam] = useState(false);
  const [hamSearch, setHamSearch] = useState(false);
  const [hamtop, setHamTop] = useState(false);

  const main_page = typeof window !== 'undefined' ? window.location : ''
  var page = ''
  if (main_page.pathname) {
    page = main_page.pathname.split("/")[1]
  }

  const closeHam = () => {
    setHam(!ham);
    setHamTop(!hamtop);
    if(!ham) {
        $(".header-wrapper .header-search, .header-wrapper .login-wrap").addClass("hidden")

    }else{
      $(".header-wrapper .header-search, .header-wrapper .login-wrap").removeClass("hidden")
    }
     console.log('sdkjsd' + ham)
  };

  const closeHamSearch = () => {
    setHamSearch(!hamSearch);
  };


  const handlerClick = (item, index) => {
    if (item.Sub_Menus.length > 0) {
      let a = state.map((item, i) => {
        if (i === index) {
          if (item.isopen) {
            setIssubmenu([]);
            return { ...item, isopen: false };
          } else {
            setIssubmenu(item.Sub_Menus);
            return { ...item, isopen: true };
          }
        } else {
          return { ...item, isopen: false };
        }
      });
      setState(a);
    } else {
      setIssubmenu([]);
      let a = state.map((item, i) => {
        return { ...item, isopen: false };
      });
      setState(a);
    }
  };

  const handlerClickTop = (item, index) => {
    if (item.Add_Sub_Menu.length > 0) {
      let a = statetop.map((item, i) => {
        if (i === index) {
          if (item.isopen) {
            setIssubmenuTop([]);
            return { ...item, isopen: false };
          } else {
            setIssubmenuTop(item.Add_Sub_Menu);
            return { ...item, isopen: true };
          }
        } else {
          return { ...item, isopen: false };
        }
      });
      setStateTop(a);
    } else {
      setIssubmenuTop([]);
      let a = statetop.map((item, i) => {
        return { ...item, isopen: false };
      });
      setStateTop(a);
    }
  };

  const closeStickyHeader = () =>{
    sessionStorage.setItem("StickyHeadet", 1)
    setSticky(false) 
    $('body').removeClass('withStickyBand');
  }


  return (
    <>
      { /*sticky &&
        <div className="headerSticky">
          <p><strong>Looking to Sell or Let your home?</strong> Book your <Link to={`/property-valuation/`}>free property valuation</Link> today <i className="icon-white-arrow" ></i></p>
          <p className="mobile-views"><strong>Looking to Sell or Let?</strong> Book a <Link to={`/property-valuation/`}>valuation</Link> today <i className="icon-white-arrow" ></i></p>
          <i className="icon-x-mark icon-x-mark-sticky" onClick={closeStickyHeader}></i></div>
      */
      }

      <header className={`header ${sticky ? 'with-band' : 'no-band'}`} id="pageheader">

        <Helmet>
        </Helmet>

        <Container>
          <Row>
            <Col sm={12}>
              <div className="header-wrapper">
                <div className="logo">
                  <Link to="/">

                    <img src={logoNew} alt={`${sitename} Estate Agents logo`} />

                  </Link>
                </div>
                <nav className="nav-bar">
                  <ul className="nav-menu">
                    {/* <li className="dropdown-top position-relative find-a-property">
                    <div className="main-title">Find a property</div>
                    <div className="sub-menu-top">
                      <div className="sub-menu find-a-property-popup">
                        <div className="title">Find your perfect home</div>
                        <SearchForm page={page} />
                      </div>
                    </div>
                  </li> */}
                    {top_links &&
                      top_links.map((item, i) => (
                        <>
                          {item.Add_Sub_Menu.length !== 0 ?
                            <li className="dropdown-top position-relative">
                              <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
                              {item.Add_Sub_Menu &&
                                <div className="sub-menu-top" >
                                  <div className="sub-menu" >
                                    <div className="menu-lists" >
                                      {item.Add_Sub_Menu.map((subLink, k) => {
                                        return (
                                          <GenerateLink className={k === 0 ? "none" : "border-after"} link={subLink.Submenu_Link}>{parse(subLink.Submenu_Label)}</GenerateLink>
                                        )
                                      }
                                      )}
                                    </div>
                                   {/* <div className="valuation-block">
                                      <div className="h3">Selling your home <span>doesn’t need to be difficult.</span></div>
                                    </div>
                                    */}
                                  </div>

                                </div>
                              }
                            </li> :
                            <li>
                              <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
                            </li>
                          }
                        </>
                      )
                      )}
                  </ul>
                </nav>
                <div className="menu-icons">
                 {/* <a className="header-search" onClick={(e) => { e.preventDefault(); closeHamSearch(); }}>
                    <i className="icon-search-header"></i>
                  </a>
                  */}
                  {/*<div className="signup-wrap dropdown-top position-relative">
                    <a href="https://street.co.uk/login" target="_blank">
                      <i className="icon-signup"></i> Sign in
                    </a>
                    <div class="sub-menu-top">
                      <div class="sub-menu">
                        <a href="https://street.co.uk/login" target="_blank">Login to Redbrik</a>
                      </div>
                        </div>

                  </div>*/}
                  <div className="login-wrap dropdown-top position-relative">
                    { isMobileOnly && isClient ?
                      <>
                        <a href="javascript:void(0);" className="login-wrap">
                          <i className="icon-call"></i>
                          <i className="icon-call-selected"></i>
                        </a>
                        <div class="sub-menu-top contact">
                          <div class="sub-menu">
                            {
                              parse(site_contact)
                            }
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <Link className="login-wrap" to="/contact/">
                          <i className="icon-call"></i>
                          <i className="icon-call-selected"></i>
                        </Link>
                        <div class="sub-menu-top contact">
                          <div class="sub-menu">
                            {
                              parse(site_contact)
                            }
                          </div>
                        </div>
                      </>
                    }
                  </div>
                  <div className="val-btn">
                  <Link className={`btn btn-white`} to={'/property-valuation'}>Book a Valuation</Link>
                  </div>                 
               
                  <button
                    onClick={closeHam}
                    className={ham ? "hamburger cross" : "hamburger"}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <i className="icon-cross"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className={`${hamSearch ? "menu-list-wrapper" : "menu-list-wrapper d-none"} wrapper-find-property`}>
          <div className="dropdown-top position-relative find-a-property">
            <button class="hamburger cross" onClick={closeHamSearch}><span></span><span></span><span></span><i className="icon-cross"></i></button>
            <div className="sub-menu-top">
              <div className="sub-menu find-a-property-popup">
                <div className="title">Find your perfect home</div>
                <SearchForm page={page} />
              </div>
            </div>
          </div>
        </div>

        <div className={ham ? "menu-list-wrapper" : "menu-list-wrapper d-none"}>
          <nav className="menu-bar">
            <ul>
              {state &&
                state.map((item, i) => (
                  <li onClick={() => handlerClick(item, i)}>
                      {item.Parent_Link ?
                        <GenerateLink link={item.Parent_Link}>{item.Parent_Label}</GenerateLink> : <GenerateLink link={item.Parent_Link}>{item.Parent_Label}</GenerateLink>}
                      {" "}
                      {item.Sub_Menus.length > 0 && (
                        <i
                          className={item.isopen ? "icon-minus-burger" : "icon-plus-burger"}
                        ></i>
                      )}
                    <div className={item.isopen ? "inside-list open-list" : "inside-list"}>
                      <ul className="inside-listing">
                        {item.Sub_Menus &&
                          item.Sub_Menus.map((newitem) => (
                            <li>
                              <GenerateLink link={newitem.Link}>{newitem.Label}</GenerateLink>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </li>
                ))}
                {/*<li><a href="https://street.co.uk/login" target="_blank">Sign in</a></li>*/}
            </ul>


            {/* <ul className="mobile-nav-two">
              {statetop &&
                statetop.map((item, i) => (
                  <li onClick={() => handlerClickTop(item, i)}>
                    <Link>
                      {item.Link ?
                        <GenerateLink link={item.Link}>{item.Label}</GenerateLink> : <>{item.Label}</>}
                      {" "}
                      {item.Add_Sub_Menu.length > 0 && (
                        <i
                          className={item.isopen ? "icon-minus" : "icon-plus"}
                        ></i>
                      )}
                    </Link>
                    <div className={item.isopen ? "" : "inside-list"}>
                      <ul className="inside-listing">
                        {item.Add_Sub_Menu &&
                          item.Add_Sub_Menu.map((newitem) => (
                            <li>
                              <GenerateLink link={newitem.Submenu_Link}>{newitem.Submenu_Label}</GenerateLink>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </li>
                ))}
            </ul> */}
          </nav>
          {/*
        <ul className="privacy-link">
          {quick_links &&
            quick_links.map((item, i) => (
              <li>
                <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
              </li>
            )
          )}
        </ul>*/}
          <div className="privacy-link valuation-overlay">
            { /* <div className="h3">How much is my property worth?</div>
            <p>Get the full, bespoke sales or letting valuation your property deserves from our Sheffield or Chesterfield team.</p> */}
            <Link className={`btn`} to={'/property-valuation'}>Book a Valuation</Link>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;



